<template>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
  <menu_tempo/>
  <header_tempo/>
  <service_tempo/>
  <careers_tempo/>
  <about_contact/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import menu_tempo from './components/menu_tempo.vue'
import header_tempo from './components/header_tempo.vue'
import service_tempo from './components/service_tempo.vue'
import careers_tempo from './components/careers_tempo.vue'
import about_contact from './components/about_contact.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    menu_tempo,
    header_tempo,
    service_tempo,
    careers_tempo,
    about_contact
  }
  
}
import $ from 'jquery'
$(document).ready(function(){
  $('.menu_icon').click(function(){
    $('nav').slideToggle();
  });
});
$(document).ready(function(){
  $('div').scroll(function(){
      const scroll = $('div').scrollTop();
      if(scroll > 150){
        $("#navigation").css("background", "#FFFFFF");
        $("#navigation").css("box-shadow", "0px 1px 1px rgba(0, 0, 0, 0.25)");
        $("#navigation").css("display", "block");
      }
      else{
        $("#navigation").css({"background": "none", 
          "box-shadow": "none"});
      }
    })
})

</script>

<style lang="scss">
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  html{
    font-style: normal;
  }
  #app{
    max-width: 100vw;
    min-width: 375px;
    height: 100vh;
    scroll-behavior: smooth;
    overflow-y: scroll;
    background-color: #FFFFFF;
    overflow-x: hidden;
  }
  .menu{
      max-width: 1024px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  @import "./assets/sass/reponsive_moile/reponsive_mobile.scss";
  @import "./assets/sass/table_responsive/table_responsive.scss";
</style>
