<template>
    <div id="careers">
        <button>Join with us for every technology stack</button>
        <div class="list_logo">
            <img src="../assets/img/python.svg" alt="logo">
            <img src="../assets/img/go.svg" alt="logo">
            <img src="../assets/img/laravel.svg" alt="logo">
            <img src="../assets/img/spring.svg" alt="logo">
            <img src="../assets/img/flutter.svg" alt="logo">
            <img src="../assets/img/react.svg" alt="logo">
        </div>
    </div>
  </template>
  
  <script>
  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    @import "../assets/sass/careers.scss";
  </style>
  