<template>
    <div id="header">
      <div class="circle_background">
        <img src="../assets/img/background.svg" alt="background">
        <div class="circle3"></div>
        <div class="circle2"></div>
        <div class="circle1"></div>
      </div>
      <div class="landing">
          <div class="landing_title">
            <h1>Tempo Software <br> Company <span>Offshore</span></h1>
            <p>Comprehensive development service.</p>
            <button>Contact Us</button>
          </div>
          <div class="landing_img">
            <img src="../assets/img/IT_Support_USP1.svg" alt="image">
        </div>
        </div>
      <div class="logo_text_list">
          <p>A LOT OF HAPPY CUSTOMER</p>
          <div class="list_logo_landing">
                <img src="../assets/img/logo_text1.svg" alt="logo_text">
                <img src="../assets/img/logo_text2.svg" alt="logo_text">
                <img src="../assets/img/logo_text3.svg" alt="logo_text">
                <img src="../assets/img/logo_text4.svg" alt="logo_text">
                <img src="../assets/img/logo_text5.svg" alt="logo_text">
              </div>
        </div>
        
    </div>
  </template>
  
  <script>
    
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    @import "../assets/sass/header.scss";
  </style>
  