<template>
    <div id="service">
        <div class="space">
            <div class="total">
                <div class="list_platform_1">
                    <div class="platform_item">System</div>
                    <div class="platform_item">Blockchain</div>
                </div>
                <div class="list_platform_2">
                    <div class="platform_item">Application</div>
                    <div class="platform_item">AI</div>
                </div>
            </div>
        </div>
        <div class="info_text">
            <div class="content_top">
                <div class="content">
                    <h1>Tempo offers offshore services</h1>
                    <span>in many development areas</span>
                    <p>As an important partner of our customers, we support everything 
                    from design to development, operation, and maintenance of
                     various systems.</p>
                </div>
                <div class="img_background">
                    <img src="../assets/img/background.svg" alt="background">
                </div>
            </div>
        </div>
        <div class="content_bot">
            <div class="circle">
                <div class="circle_1"></div>
                <div class="circle_2"></div>
                <div class="circle_3"></div>
            </div>
            <div class="info_title">
                <h2>Provides a development</h2>
                <span>form suitable for KH requirements</span>
            </div>
            <div class="info_detail">
                <table>
                    <tr>
                        <th class="item1"><span>Shape</span></th>
                        <th class="item2">
                            <span>Labo</span>
                            <ul>
                                <li>Power x time</li>
                            </ul>
                        </th>
                        <th class="item3">
                            <span>Project based</span>
                            <ul>
                                <li>Product based contract</li>
                            </ul>
                        </th>
                    </tr>
                    <tr>
                        <th class="item4"><span>Characteristic</span></th>
                        <th class="item5">
                            <ul>
                                <li>Form a team in a weak</li>
                                <li>Accumulate development experience for long-term projects</li>
                                <li>Easily change requirements</li>
                            </ul>
                        </th>
                        <th class="item6">
                            <ul>
                                <li>Ease of cost control</li>
                                <li>Quick product confirmation</li>
                                <li>Product quality assurance is handled by the development team</li>
                            </ul>
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    @import "../assets/sass/service.scss";
  </style>
  