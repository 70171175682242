<template>
    <div id="navigation">
            <div class="menu">
                <div class="logo_responsive">
                        <img src="../assets/img/logo_reposive.svg" alt="logo_reposive">
                </div>
                <div class="menu_icon">
                    <img src="../assets/img/menu_reponsive.svg" alt="icon">
                </div>
            </div>
            <div class="menu">
                <div class="logo">
                    <img src="../assets/img/logo_black.svg" alt="logo">
                </div>
                <nav>
                    <ul>
                        <li><a href="#header">Home</a></li>
                        <li><a href="#service">Services</a></li>
                        <li><a href="#about_contact">About</a></li>
                        <li><a href="#careers">Careers</a></li>
                        <li><a href="#about_contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
    </div>
  </template>
<script src="">
 
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    @import "../assets/sass/menu.scss";
    


  </style>
  
  