<template>
    <div id="about_contact">
        <div class="menu">
            <div class="about">
                <img src="../assets/img/logo-trademark.svg" alt="logo">
                <p>Amet minim mollit non deserunt ullamco est <br> sit aliqua dolor do amet sint. Velit officia <br> consequat duis enim velit mollit. Exercitation<br> veniam consequat sunt nostrud amet.</p>
            </div>
            <div class="service_contact">
                <div class="service">
                    <span class="title_text">Services</span>
                    <ul>
                        <li><a href="">Ui/Ux Design</a></li>
                        <li><a href="">FrontEnd Deverlop</a></li>
                        <li><a href="">Search Engine</a></li>
                        <li><a href="">Maps Services</a></li>
                        <li><a href="">Auto bot</a></li>
                    </ul>
                </div>
                <div class="contact">
                    <span class="title_text">Contact</span>
                    <ul>
                        <li><a href="">
                            <img src="../assets/img/mail_contact.svg" alt="">
                            <span>tb@tempo-soft.com</span>
                        </a></li>
                        <li><a href="">
                            <img src="../assets/img/phone_contact.svg" alt="">
                            <span>+84988131393</span>
                        </a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="menu">
                <div class="brand">
                    <span>© tempo-soft.com 2022</span>
                </div>
                <div class="infor_networt">
                    <ul>
                        <li><a href=""><img src="../assets/img/linke.svg" alt="link"></a></li>
                        <li><a href=""><img src="../assets/img/facebook.svg" alt="link"></a></li>
                        <li><a href=""><img src="../assets/img/youtube.svg" alt="link"></a></li>
                        <li><a href=""><img src="../assets/img/instagram.svg" alt="link"></a></li>
                        <li><a href=""><img src="../assets/img/twitter.svg" alt="link"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    @import "../assets/sass/about_contact.scss";
  </style>
  